import Layout from "../../../components/layout";
import { Link } from "gatsby";
import MainContent from "../../../components/maincontent";
import PageBanner from "../../../components/banner/banner";
import React from "react";
import Seo from "../../../components/seo";

const currentYear = "2024";
const dateRange = "October 9th - October 13th";
const IndexPage = () => (
  <Layout>
    <Seo
      title="Home"
      description="A local assembly in central Illinois"
      lang="en"
      meta={[
        {
          content: "God, Jesus, Gospel, Heaven, Salvation, Saved, Grace, Free, How, Mid-Acts, Romans, Charity Grace Bible Church, Illinois",
          name: "keywords"
        }
      ]}
    />

    <PageBanner
      hasImage={true}
      hasOverlay={true}
      imageUrl="/assets/images/DSC_7115.jpg"
    />

    <MainContent
      hasArticle={false}
      hasBanner={true}
    >
      <h1 className='main-header'>Registration Complete</h1>
      <p>Thank you for registering! We look forward to seeing you!</p>
      <div className="card">
        <div className="grid grid--12">

          <div className="grid--4 align--middle">
            <img src="/assets/images/camp2019.jpg" alt="A&nbsp;group&nbsp;of&nbsp;people&nbsp;stand&nbsp;in&nbsp;front&nbsp;of&nbsp;a&nbsp;chapel." />
          </div>

            <div className="grid--8 align--left">
                <h2>{currentYear} Camp Schedule ({dateRange})</h2>
                <p><strong>Posted: May 31th, 2024 - 8:00PM</strong></p>
                <p>The {currentYear} Charity Grace Bible Camp Schedule is Now Available! Camp will be held {dateRange}</p>
                <Link className="button button--blue no-margin" to={`/camp/${currentYear}/`}>View Schedule</Link>
            </div>

        </div>
      </div>

    </MainContent>
  </Layout>
);

export default IndexPage;
